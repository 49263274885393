import React from "react"
import Image from "../../image"

const BgCardsText = ({ title, text, cards, renderSubTitle, dataTheme }) => {
  return (
    <section data-theme={dataTheme} className="BgCardsText">
      <div className="container container--lg margin-top--lg margin-bottom--lg">
        <div className="text--component text--center margin-bottom--md">
          <h2 className="margin-bottom--xs">{title}</h2>
          {text && (
            <p style={{ maxWidth: "800px", margin: "0 auto" }}>{text}</p>
          )}

          {renderSubTitle && (
            <h3 className="text--center margin-bottom--md margin-top--lg">
              {renderSubTitle}
            </h3>
          )}
        </div>
        <div className="grid grid--gap-md text--center ">
          {cards &&
            cards.map(card => (
              <div
                key={card.title}
                className="col col--12 col--sm-4 margin-bottom--lg"
              >
                <div className="BgCardsTextCard">
                  <div className="widthIcons" style={{ width: "100%" }}>
                    <Image alt={card.alt} filename={card.Filename} />
                  </div>
                  <div className="text--component margin-top--sm margin--sm text--left">
                    <h3 className="text--md">{card.title}</h3>
                    <p>{card.text}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  )
}
export default BgCardsText
