import React from 'react';
import Logo from '@assets/blueshieldca.svg';

import Layout from '../../../components/layout';
import Seo from '../../../components/seo';
import HeroBgImageListingST from '../../../components/staticSections/HeroBgImageListingST/HeroBgImageListingST';
import BgCardsText from '../../../components/sections/bgCardsText/bgCardsText';
import OutcomeST from '../../../components/staticSections/OutcomeST/OutcomeST';
import Testimonial from '../../../components/sections/testimonial/testimonial';
import FaqST from '../../../components/staticSections/FaqST/FaqST';
import CtaST from '../../../components/staticSections/CtaST/CtaST';
import Segment from '../../../components/segment';

const IndexPage = () => {
  const lang = 'us_en';
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="Covered by Blue Shield of California"
      showFooter
      blueshieldca
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="BSCA" src={Logo} />
      }
      dataTheme="white"
    >
      <Segment id="15aFPY6NamUQUAN3oIEqSx0D2mNElDF7" />
      <Seo title="Blue Shield of California - Granted" language="en" />
      <HeroBgImageListingST
        lang={lang}
        dataTheme="light"
        calltext="get started today"
        calldataGoal="Download Button Tapped"
        callhref="https://app.adjust.com/qabuft3"
        callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs btn--lgMob"
      />
      <BgCardsText
        title="Can an app really help with joint pain?"
        text="Specific exercises improve joint stability and protect it from damage. This app makes
it easy to connect with a personal licensed physical therapist, get reminders, and
track your progress. This boosts motivation and research shows it improves
treatment results."
        cards={[
          {
            alt: 'Left',
            Filename: 'img--left.png',
            title: 'A personal physical therapist',
            text: 'Get 1-on-1 support from a licensed physical therapist through video or voice call. Available on-demand throughout your treatment.',
          },
          {
            alt: 'Center',
            Filename: 'img--center.png',
            title: 'Exercise and physical activities',
            text: 'Every day you will get two easy exercises with video instructions that help strengthen muscles around joints to reduce pain and increase mobility.',
          },
          {
            alt: 'Right',
            Filename: 'img--right.png',
            title: 'Goals and progress tracking',
            text: 'Set goals for your pain improvement and track your progress each week in the app. Get daily notifications on your phone to stay motivated.',
          },
        ]}
        renderDownloadButton
        ctaButtonText="Download and sign up"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://us-onboarding.jointacademy.com/"
      />
      <OutcomeST lang={lang} />
      <Testimonial
        videoSrcURL="https://player.vimeo.com/video/605400985?color=fff&title=0&byline=0&portrait=0"
        videoTitle="“I can’t imagine a day without Joint Academy”"
      />
      <FaqST lang={lang} />
      <CtaST
        lang={lang}
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
